<template>
  <div>
    <el-dialog
      title="分享课程"
      custom-class="share-course-dialog"
      :show-close="showClose"
      top="7.5vh"
      width="790px"
      :append-to-body="true"
      :close-on-click-modal="false"
      :visible.sync="outerVisible"
      :before-close="outerBeforeClose"
      @close="outerClose"
    >
      <div slot="title" class="color_26">
        <i class="icon-share fontsize_14"></i>
        <span class="margin_left8">分享课程</span>
        <span v-if="shareData.title">：{{shareData.title}}</span>
      </div>
      <div class="margin_bot24 padding_0_24" v-if="entryType == 'new'">
        <label class="fontsize_14 color_26 lineheight_20 margin_bot8 flex">选择要分享的课程</label>
        <div class="flex flex_jcbetween flex_acenter bgc_F5F5F5 padding_10_16 bdr4">
          <div class="flex flex_acenter" v-if="shareData.course_id">
            <el-image class="share-img-select" :src="course_cover"></el-image>
            <span class="margin_left8 fontsize_14 color_26">{{course_title}}</span>
          </div>
          <div style="width: 100px;height:60px;" class="bdr4" v-else></div>
          <customButton
            plain
            :roundPX="4"
            color="#E74362"
            :width="96"
            :height="32"
            @click="openCourseList"
            class="sureBtn pointer"
            style="background-color:#f5f5f5"
          >
            <span class="fontsize_14">选择课程</span>
          </customButton>
        </div>
      </div>
      <div
        class="margin_bot32 margin_top16 padding_0_24 flex flex_acenter"
        v-if="entryType =='set' || entryType =='other'"
      >
        <label class="fontsize_14 color_26 lineheight_20">重置链接：</label>
        <el-switch
          :width="52"
          active-icon-class="active-link"
          inactive-icon-class="inactive-link"
          active-color="#e74362"
          inactive-color="#d4d4d4"
          :active-value="1"
          :inactive-value="0"
          v-model="shareData.if_reset_url"
        ></el-switch>
      </div>
      <!-- 分割线 -->
      <div
        class="flex flex_jcbetween flex_acenter fontsize_12 color_26 version_line"
        v-if="(entryType =='set' || entryType =='other' || entryType === 'new') && shareData.is_vip==0"
      >
        <span class="flexauto"></span>
        <span class="margin_left13 margin_right13">
          <i class="icon-share-suo"></i> 以下设置是付费版专享，
          <a @click="toUpgrade">升级版本</a> 后可解除此限制
        </span>
        <span class="flexauto"></span>
      </div>

      <div class="margin_bot24 padding_0_24 flex">
        <label class="fontsize_14 lineheight_20 margin_bot8" :class="restrictColor">访问课程：</label>
        <div class="margin_left16 flexauto margin_top4">
          <div class v-for="(item, index) in sharetype" :key="index">
            <div
              class="flex margin_bot16"
              :class="restrictPointer"
              style="width:fit-content;"
              @click="toggleVisit(item)"
            >
              <i
                class="fontsize_16"
                :class="{ 'icon-select-fw': item.type==shareData.open_way,'icon-active':item.type==shareData.open_way&&shareData.is_vip==1, 'icon-multiple-notselected': item.type!=shareData.open_way, }"
              >
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
              <span class="margin_left8 fontsize_14" :class="restrictColor">{{ item.name }}</span>
            </div>
            <div
              class="bdr4 bgc_F5F5F5 padding_16 margin_bot16"
              style="margin-right: 98px;"
              v-if="shareData.open_way==item.type && item.type=='code'"
            >
              <div
                class="flex flex_acenter"
                style="width:fit-content;"
                :class="{ 'margin_top13': cindex > 0,
               }"
                v-for="(citem, cindex) in sharecode"
                :key="cindex"
                @click="toggleCode(citem)"
              >
                <i
                  class="fontsize_16"
                  :class="{ 'icon-select-fw': citem.type==shareData.code_type,'icon-active':citem.type==shareData.code_type&&shareData.is_vip==1, 'icon-multiple-notselected': citem.type!=shareData.code_type }"
                >
                  <span class="path1"></span>
                  <span class="path2"></span>
                </i>
                <span class="margin_left8">{{ citem.name }}</span>
                <input
                  type="text"
                  placeholder="4-6位数字/字母"
                  class="custom-code"
                  :value="shareData.code_type == 'define'?shareData.code:''"
                  v-if="citem.type == 'define'"
                  @change="codeChange"
                />
              </div>
            </div>
            <div
              class="bdr4 bgc_F5F5F5 padding_16"
              style="margin-right: 98px;"
              v-if="shareData.open_way==item.type && item.type=='teloremail'"
            >
              <div class="flex flex_acenter flex_jcbetween margin_bot8">
                <label class="fontsize_14 color_8C lineheight_20">验证手机号：</label>
                <div class="flex flex_acenter">
                  <customButton
                    :roundPX="4"
                    :color="isvip?'#E74362':'#E74362'"
                    :width="44"
                    :height="22"
                    @click="addTelEmail('tel')"
                    class="sureBtn pointer"
                  >
                    <span class="fontsize_12">新增</span>
                  </customButton>
                  <customButton
                    :roundPX="4"
                    :color="isvip?'#E74362':'#d9d9d9'"
                    :width="44"
                    hoverBgColor="#fff"
                    hoverBorderColor="#E74362"
                    :height="22"
                    @click="importTelEmail('tel')"
                    class="sureBtn pointer margin_left10"
                    style="background:#fff;"
                  >
                    <span class="fontsize_12 color_E74362">导入</span>
                  </customButton>
                </div>
              </div>
              <div class="phone-box margin_bot16" v-if="!Array.isArray(shareData.teloremail)">
                <div
                  class="flex flex_jcbetween flex_acenter bgc_fff phone-item"
                  v-for="(item, index) in shareData.teloremail.tel"
                  :key="index"
                >
                  <span class="fontsize_14 lineheight_20" :class="restrictColor">{{item}}</span>
                  <span>
                    <i
                      class="icon-share-edit fontsize_12"
                      :class="{'btn-disabled':!isvip}"
                      @click="editTelEmail('tel',item,index)"
                    ></i>
                    <i
                      class="icon-share-del fontsize_12 margin_left8"
                      :class="{'btn-disabled':!isvip}"
                      @click="removeTelEmail('tel',index)"
                    ></i>
                  </span>
                </div>
              </div>
              <div class="flex flex_acenter flex_jcbetween margin_bot8">
                <label class="fontsize_14 color_8C lineheight_20">验证邮箱：</label>
                <div class="flex flex_acenter">
                  <customButton
                    :roundPX="4"
                    :color="isvip?'#E74362':'#d9d9d9'"
                    :width="44"
                    :height="22"
                    @click="addTelEmail('email')"
                    class="sureBtn pointer"
                  >
                    <span class="fontsize_12">新增</span>
                  </customButton>
                  <customButton
                    :roundPX="4"
                    :color="isvip?'#E74362':'#d9d9d9'"
                    :width="44"
                    hoverBgColor="#fff"
                    hoverBorderColor="#E74362"
                    :height="22"
                    @click="importTelEmail('email')"
                    class="sureBtn pointer margin_left10"
                    style="background:#fff;"
                  >
                    <span class="fontsize_12 color_E74362">导入</span>
                  </customButton>
                </div>
              </div>
              <div class="email-box" v-if="!Array.isArray(shareData.teloremail)">
                <div
                  class="flex flex_jcbetween flex_acenter bgc_fff email-item"
                  v-for="(item, index) in shareData.teloremail.email"
                  :key="index"
                >
                  <span class="fontsize_14 lineheight_20" :class="restrictColor">{{item}}</span>
                  <span>
                    <i
                      class="icon-share-edit fontsize_12"
                      :class="{'btn-disabled':!isvip}"
                      @click="editTelEmail('email',item,index)"
                    ></i>
                    <i
                      class="icon-share-del fontsize_12 margin_left8"
                      :class="{'btn-disabled':!isvip}"
                      @click="removeTelEmail('email',index)"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="margin_bot24 padding_0_24 flex">
        <label class="fontsize_14 lineheight_20 margin_bot8" :class="restrictColor">有效期限：</label>
        <div class="margin_left16 flex flexauto" style="margin-top: -6px;">
          <div
            class="flex flex_acenter margin_right24"
            v-for="(item, index) in sharevalid"
            :key="index"
          >
            <div class="flex" :class="restrictPointer" @click="toggleValid(item)">
              <i
                class="fontsize_16"
                :class="{ 'icon-select-fw':shareData.valid_type==item.type,'icon-active':item.type==shareData.valid_type&&shareData.is_vip==1, 'icon-multiple-notselected': shareData.valid_type!=item.type, }"
              >
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
              <span class="margin_left8" :class="restrictColor">{{ item.name }}</span>
            </div>
            <div class="flex flex_acenter" v-if="item.type == 'day'">
              <div class="day-input">
                <input
                  type="number"
                  class="input"
                  :step="1"
                  :min="1"
                  :class="restrictPointer"
                  :disabled="!isvip"
                  v-model="shareData.valid_days"
                  @blur="dayBlur"
                />
                <span class="tool-down-up">
                  <img src="@/assets/svg/down-up-2.svg" alt v-if="!isvip" />
                  <img src="@/assets/svg/down-up.svg" alt v-else />
                  <i class="tools-up" :class="restrictPointer" @click="handleDay('up')"></i>
                  <i class="tools-down" :class="restrictPointer" @click="handleDay('down')"></i>
                </span>
              </div>
              <span class="fontsize_14 color_8C">天</span>
            </div>
            <div class="prel" v-if="item.type == 'time'">
              <el-date-picker
                class="picker"
                :disabled="!isvip"
                :class="!isvip?'color_8C':'color_59'"
                prefix-icon=" "
                clear-icon=" "
                v-model="shareData.valid_time"
                type="datetime"
                size="mini"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期时间"
              ></el-date-picker>
              <i class="icon-share-rili picker-icon-calendar"></i>
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        width="540px"
        title="内层 Dialog"
        :visible.sync="innerVisible"
        append-to-body
        custom-class="inner-course-dialog"
        :close-on-click-modal="false"
        :modal="true"
      >
        <el-input
          class="course-search_input color_BFBFBF fontsize_14 margin_bot16"
          placeholder="课程关键字搜索"
          v-model="title"
          @keyup.enter.native="getShareCourse"
          @input="getShareCourse"
        >
          <i slot="prefix" class="icon-search color_8C fontsize_16 lineheight_32"></i>
        </el-input>
        <div class="course-box">
          <div
            class="flex flex_acenter bgc_F5F5F5 padding_6_16 course-item"
            :class="{ 'course-item_active': item.check }"
            v-for="(item, index) in courses"
            :key="index"
            @click="selectCourse(item)"
          >
            <div class="course-item_select" v-if="item.check">
              <img src="@/assets/select_course.png" class="course-item_select-img" />
              <i class="icon-share-select"></i>
            </div>
            <el-image class="course-img" :src="item.cover"></el-image>
            <span class="fontsize_14">{{item.title}}</span>
          </div>
          <div class="flex flex_acenter flex_center bgc_F5F5F5 padding_16" v-if="courses.length==0">
            <span class="fontsize_14">暂无数据</span>
          </div>
        </div>
        <div slot="footer" class="dialog-footer flex flex_acenter flex_center">
          <customButton
            :roundPX="4"
            color="#E74362"
            :width="96"
            :height="32"
            hoverBgColor="#B5102F"
            @click="confirmSelect"
            class="sureBtn pointer"
          >
            <span class="fontsize_14">确定</span>
          </customButton>
          <customButton
            :roundPX="4"
            color="#f5f5f5"
            :width="96"
            :height="32"
            hoverBorderColor="#d9d9d9"
            @click="innerVisible = false"
            class="sureBtn pointer margin_left100"
            style="background:#fff;border-color: #d9d9d9;"
          >
            <span class="fontsize_14 color_8C">取 消</span>
          </customButton>
        </div>
      </el-dialog>
      <el-dialog
        width="540px"
        title="内层 Dialog"
        :visible.sync="telEmailVisible"
        append-to-body
        custom-class="teloremail-dialog"
        :close-on-click-modal="false"
        :modal="true"
        @close="innerClose"
      >
        <el-input class="teloremail_input color_BFBFBF fontsize_14" v-model="telEmail"></el-input>
        <div slot="footer" class="dialog-footer flex flex_acenter flex_center">
          <customButton
            :roundPX="4"
            color="#E74362"
            :width="82"
            :height="32"
            hoverBgColor="#B5102F"
            @click="confirmAddTelEmail"
            class="sureBtn pointer"
          >
            <span class="fontsize_14">确定</span>
          </customButton>
          <customButton
            :roundPX="4"
            color="#f5f5f5"
            :width="82"
            :height="32"
            hoverBorderColor="#d9d9d9"
            @click="telEmailVisible = false"
            class="sureBtn pointer margin_left16"
            style="background:#fff;border-color: #d9d9d9;"
          >
            <span class="fontsize_14 color_8C">取 消</span>
          </customButton>
        </div>
      </el-dialog>
      <div slot="footer" class="dialog-footer flex flex_acenter flex_center">
        <customButton
          :roundPX="4"
          color="#E74362"
          :width="96"
          :height="32"
          @click="updateShare"
          v-if="entryType=='set'"
          class="sureBtn btnconfrim pointer"
        >
          <span class="fontsize_14">更新</span>
        </customButton>
        <customButton
          :roundPX="4"
          color="#E74362"
          :width="96"
          :height="32"
          @click="confirmShare"
          v-else
          class="sureBtn btnconfrim pointer"
        >
          <span class="fontsize_14">确定分享</span>
        </customButton>
        <customButton
          :roundPX="4"
          color="#fff"
          :width="96"
          :height="32"
          @click="outerVisible = false"
          class="sureBtn btnclose pointer margin_left100"
        >
          <span class="fontsize_14 color_8C">取 消</span>
        </customButton>
      </div>
    </el-dialog>
    <el-dialog
      width="540px"
      title="内层 Dialog"
      :visible.sync="importVisible"
      append-to-body
      custom-class="import-dialog"
      :close-on-click-modal="false"
      :modal="true"
      @close="handleClose"
    >
      <div>
        <p class="fontsize_14 color_26 margin_bot10">导入文件（.xls/.xlsx）</p>
        <el-upload
          style="width:100%"
          :action="importAction"
          :headers="headers"
          name="file"
          :show-file-list="false"
          accept=".xls, .xlsx"
          :on-error="updateError"
          :on-success="updateSuccess"
        >
          <el-input
            v-model="inputVal"
            disabled
            class="color_BFBFBF fontsize_12 import-input"
            style="width:100%"
            placeholder="单击选择文件"
          ></el-input>
        </el-upload>
        <p class="fontsize_12 margin_top5" style="padding-top:9px;">
          <a :href="downUrl" class="color_E74362" style="text-decoration: unset;">下载导入模板</a>
        </p>
      </div>
      <div slot="footer" class="dialog-footer flex flex_acenter flex_center">
        <customButton
          :roundPX="4"
          color="#E74362"
          :width="82"
          :height="32"
          hoverBgColor="#B5102F"
          @click="confirmImport"
          class="sureBtn pointer"
        >
          <span class="fontsize_14">确定</span>
        </customButton>
        <customButton
          :roundPX="4"
          color="#f5f5f5"
          :width="82"
          :height="32"
          hoverBorderColor="#d9d9d9"
          @click="importVisible = false"
          class="sureBtn pointer margin_left32"
          style="background:#fff;border-color: #d9d9d9;"
        >
          <span class="fontsize_14 color_8C">取 消</span>
        </customButton>
      </div>
    </el-dialog>
    <fd-loading :loading="fdloading"></fd-loading>
    <fd-upgrade-dialog :closeOnClickModal="false" :show.sync="upgradeDialogVisible"></fd-upgrade-dialog>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import customButton from "@/renderer/components/common/button.vue";
import { getToken } from "@/libs/utils/auth";
export default {
  components: {
    customButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: false,
    },
    restrict: {
      type: Boolean,
      default: false,
    },
    shareData: {
      type: Object,
      default: () => {
        return {
          code: "",
          title: "",
          open_way: "",
          code_type: "",
          valid_type: "",
          teloremail: {
            email: [],
            tel: [],
          },
          valid_time: "",
          valid_days: "",
          if_reset_url: 0,
          importVisible: false,
          importTitle: "导入文件（.xls/.xlsx）",
        };
      },
    },
    entryType: {
      type: String,
      default: "new", //new:新增课程、set：分享设置 、other：内页分享
    },
    update: Function,
  },
  data() {
    return {
      importVisible: false,
      outerVisible: false,
      innerVisible: false,
      telEmailVisible: false,
      fdloading: false,
      upgradeDialogVisible: false,
      sharetype: [
        { name: "直接打开", type: "open", check: true },
        { name: "需要访问码", type: "code", check: false },
        { name: "手机号/邮箱验证", type: "teloremail", check: false },
      ],
      sharecode: [
        { name: "系统随机生成访问码", type: "random", check: true },
        { name: "自定义访问码", type: "define", check: false },
      ],
      sharevalid: [
        { name: "永久有效", type: "always", check: true },
        { name: "自定义天数", type: "day", check: false },
        { name: "自定义失效时间", type: "time", check: false },
      ],
      title: "",
      courses: [],
      restlink: "",
      course_id: "",
      course_cover: "",
      course_title: "",
      is_search: true,
      telEmail: "",
      addType: "",
      handleIndex: "",
      handleType: "",
      token: null,
      importAction: "",
      inputVal: "",
      downUrl: "",
      importData: [],
      https: process.env.VUE_APP_API,
    };
  },
  async created() {
    const token = await getToken();
    this.token = token;
  },
  watch: {
    visible(nval) {
      if (nval) {
        this.outerVisible = true;
      } else {
        this.outerVisible = false;
      }
    },
  },
  computed: {
    isvip() {
      return this.shareData.is_vip && this.shareData.is_vip == 1;
    },
    restrictColor() {
      return !this.isvip ? "color_8C" : "color_26";
    },
    restrictPointer() {
      return !this.isvip ? "not-allowed" : "pointer";
    },
    headers() {
      return {
        Authorization: "Bearer " + this.token,
      };
    },
  },
  mounted() {
    this.getShareCourse();
  },
  methods: {
    ...mapActions([
      "SetShareDetail",
      "GetShareCourse",
      "ConfirmShare",
      "SetShare",
      "SetCourseShare",
    ]),
    dayBlur(e) {
      let { value, valueAsNumber } = e.target;
      if (valueAsNumber) {
        let num = parseInt(valueAsNumber);
        if (num != valueAsNumber) this.shareData.valid_days = num;
      } else {
        this.shareData.valid_days = 1;
      }
    },
    getShareCourse() {
      this.GetShareCourse({ title: this.title })
        .then((res) => {
          if (res.errorcode === 0) {
            res.data.map((v) => (v.check = false));
            this.courses = res.data;
            this.is_search = true;
          }
        })
        .catch((err) => {
          console.log("err :>> ", err);
        });
    },
    outerBeforeClose() {
      // this.$emit('close',done)
      this.$emit("update:visible", false);
    },
    outerClose() {
      this.$emit("update:visible", false);
    },
    openCourseList() {
      this.getShareCourse();
      this.innerVisible = true;
    },
    toggleVisit(item) {
      if (!this.isvip) return;
      this.shareData.open_way = item.type;
    },
    toggleCode(item) {
      this.$set(this.shareData, "code_type", item.type);
      // this.shareData.code_type =item.type ;
    },
    toggleValid(item) {
      if (!this.isvip) return;
      this.shareData.valid_type = item.type;
    },
    codeChange(e) {
      this.shareData.code = e.target.value;
    },
    handleDay(type) {
      if (!this.isvip) return;
      if (type == "up") {
        if (this.shareData.valid_days != "") {
          this.shareData.valid_days++;
        } else {
          this.shareData.valid_days = 1;
        }
      }
      if (type == "down") {
        if (this.shareData.valid_days != "") {
          if (this.shareData.valid_days > 1) {
            this.shareData.valid_days--;
          }
        }
      }
    },
    selectCourse(item) {
      this.courses.map((v) => (v.check = false));
      item.check = true;
    },
    innerClose() {
      this.innerVisible = false;
    },
    confirmSelect() {
      let item = this.courses.filter((v) => v.check);
      if (item.length == 0) {
        this.$message("请选择课程");
        return false;
      }
      this.course_title = item[0].title;
      this.course_cover = item[0].cover;
      this.shareData.course_id = item[0].course_id;
      this.innerVisible = false;
    },
    verifyData() {
      if (this.entryType == "new" && !this.shareData.course_id) {
        this.$message.error("请选择课程");
        return false;
      }
      if (!this.shareData.open_way) {
        this.$message.error("请选择访问课程方式");
        return false;
      }
      if (!this.shareData.valid_type) {
        this.$message.error("请选择有效期限");
        return false;
      }
      let reg = /^[0-9]{4,6}$/;
      if (
        this.shareData.code_type == "define" &&
        this.shareData.open_way == "code" &&
        !reg.test(this.shareData.code)
      ) {
        this.$message.error("访问码必须由4-6位数字组成");
        return false;
      }
      
      if (
        this.shareData.open_way == "teloremail" &&
          (this.shareData.teloremail.tel.length < 1 &&
          this.shareData.teloremail.email.length < 1)
      ) {
        this.$message.error("请输入手机号/邮箱号");
        return false;
      }
      
      return true;
    },
    // 确定分享
    confirmShare() {
      if (this.verifyData()) {
        this.fdloading = true;
        if (this.entryType == "other") {
          this.SetCourseShare({ ...this.shareData })
            .then((res) => {
              this.fdloading = false;
              if (res.errorcode === 0) {
                this.$emit("update:visible", false);
                this.update(res.data.id, 2);
              }
            })
            .catch((err) => {
              this.fdloading = false;
            });
        } else {
          this.ConfirmShare({ ...this.shareData })
            .then((res) => {
              this.fdloading = false;
              if (res.errorcode === 0) {
                this.$emit("update:visible", false);
                this.update(res.data.id, 2);
              }
            })
            .catch((err) => {
              this.fdloading = false;
            });
        }
      }
    },
    // 更新分享设置
    updateShare() {
      if (this.verifyData()) {
        this.fdloading = true;
        this.SetShare({ ...this.shareData })
          .then((res) => {
            this.fdloading = false;
            if (res.errorcode === 0) {
              this.$emit("update:visible", false);
              this.update(res.data.id, 1);
            }
          })
          .catch((err) => {
            this.fdloading = false;
          });
      }
    },
    // 新增手机号 or 邮箱
    addTelEmail(type) {
      if (!this.isvip) {
        return false;
      }
      this.telEmail = "";
      this.telEmailVisible = true;
      this.addType = type;
      this.handleType = "add";
    },
    removeTelEmail(type, index) {
      if (!this.isvip) {
        return false;
      }
      this.shareData.teloremail[type].splice(index, 1);
    },
    editTelEmail(type, item, index) {
      this.telEmail = item;
      this.telEmailVisible = true;
      this.addType = type;
      this.handleType = "edit";
      this.handleIndex = index;
    },
    // 导入手机号 or 邮箱
    importTelEmail(type) {
      this.importAction =
        type == "tel"
          ? "/api/V1/user/sharecourse/importMobile"
          : "/api/V1/user/sharecourse/importEmail";
      this.downUrl =
        type == "tel"
          ? this.https + "/sharecourse/downloadMobileTemplate"
          : this.https + "/sharecourse/downloadEmailTemplate";
      this.importType = type;
      this.importVisible = true;
    },
    confirmImport() {
      if (this.importData.length == 0) {
        this.$message.error("请导入文件");
        return false;
      }
      if (this.importType == "tel") {
        this.importData.map((v) => {
          this.shareData.teloremail.tel.push(v);
        });
      }
      if (this.importType == "email") {
        this.importData.map((v) => {
          this.shareData.teloremail.email.push(v);
        });
      }
      this.importVisible = false;
    },
    // 确定 新增 or 导入
    confirmAddTelEmail() {
      const telRegex = /^(?:(?:\+|00)86)?1\d{10}$/;
      const emailRegex = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (!/\S/.test(this.telEmail)) {
        this.$message.error(
          this.addType == "tel" ? "请输入手机号" : "请输入邮箱"
        );
        return false;
      }
      if (this.addType == "tel" && !telRegex.test(this.telEmail)) {
        this.$message.error("手机号格式错误");
        return false;
      }
      if (this.addType == "email" && !emailRegex.test(this.telEmail)) {
        this.$message.error("邮箱格式错误");
        return false;
      }
      if (this.handleType == "edit") {
        this.shareData.teloremail[this.addType][this.handleIndex] =
          this.telEmail;
      } else {
        this.shareData.teloremail[this.addType].push(this.telEmail);
      }
      this.telEmailVisible = false;
    },
    toUpgrade() {
      this.$emit("update:visible", false);
      this.upgradeDialogVisible = true;
    },
    handleClose() {
      this.inputVal = "";
      this.importVisible = false;
    },
    updateSuccess(res, file, fileList) {
      if (res.errorcode === 0) {
        this.inputVal = file.name;
        this.importData = res.data;
      }
      this.$message.success(res.msg);
    },
    updateError(err, file, fileList) {
      this.$message.error("导入失败");
    },
  },
  destroyed() {
    this.$emit("update:visible", false);
  },
};
</script>
<style lang="less" scoped>
/deep/.share-course-dialog {
  border-radius: 4px;

  .el-dialog {
    &__body {
      padding: 24px 0;
    }

    &__header {
      color: #262626;
      line-height: 22px;
      padding: 17px 24px;
      background-color: #f5f5f5;
      border-radius: 4px 4px 0 0;
    }

    &__footer {
      padding: 24px;
      border-top: 0.5px solid #f0f0f0;
    }
  }

  .icon-select-fw {
    border-radius: 2px;
    overflow: hidden;
    &.icon-active {
      & .path1::before {
        width: 16px;
        border-radius: 2px;
        background-color: #e74362;
      }
      & .path2::before {
        border-radius: 2px;
        background-color: #e74362;
      }
    }
  }
  .icon-multiple-notselected {
    &::before {
      background-color: #f5f5f5;
    }
  }

  .inactive-link {
  }

  .active-link {
  }

  .sureBtn {
    &.btnconfrim {
      &:hover {
        background: #b5102f !important;
      }
    }

    &.btnclose {
      background-color: #fff;
      border: 1px solid #d9d9d9 !important;

      &:hover {
        background: #f5f5f5 !important;
      }
    }
  }

  .version_line {
    margin-bottom: 32px;

    a {
      color: #e74362;
      text-decoration: underline;
      cursor: pointer;
    }

    span:first-child,
    span:last-child {
      // display: inline-block;
      height: 1px;
      background-color: #f0f0f0;
    }
  }

  .bdr4 {
    border-radius: 4px;
  }

  .custom-code {
    width: 130px;
    height: 20px;
    font-size: 14px;
    color: #bfbfbf;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    margin-left: 4px;

    &::placeholder {
      color: #bfbfbf;
    }
  }

  .day-input {
    width: 62px;
    height: 24px;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    overflow: hidden;
    margin: 0 4px;

    .input {
      border: none;
      width: 36px;
      height: 100%;
      padding: 0;
      padding-left: 8px;
      box-sizing: border-box;
      font-size: 14px;
      color: #595959;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none !important;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }
    }

    .tool-down-up {
      display: flex;
      flex-direction: column;
      height: 24px;
      width: 26px;
      position: relative;

      .tools-down,
      .tools-up {
        height: 12px;
        position: relative;
        z-index: 1;
      }

      img {
        height: 16px;
        width: 16px;
        position: absolute;
        left: 40%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .icon-login-arrow-down {
      font-size: 8px;
    }
  }
  .updat-btn {
    width: 44px;
    height: 22px;
    line-height: 19px;
    padding: 0;
    font-size: 12px;
    background: #fff;
    border-color: #e74362;
    color: #e74362;
    &:hover {
      background: #fff;
      border-color: #e74362;
      color: #e74362;
    }
    &.btn-disabled {
      color: #fff;
      background: #d9d9d9;
      border-color: #d9d9d9;
    }
  }

  .picker {
    height: 24px;
    line-height: 24px;
    width: 177px;
    border-radius: 2px;
    margin-left: 4px;
    font-size: 14px;

    /deep/.el-input__inner {
      padding-left: 8px !important;
    }

    &.color_8C + i {
      color: #8c8c8c;
    }

    &.color_59:hover + i::before {
      color: #ea5874;
    }
  }

  .picker-icon-calendar {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 13px;
  }

  .email-box,
  .phone-box {
    max-height: 96px;
    overflow-y: scroll;

    .phone-item,
    .email-item {
      padding: 6px 16px;
      border-bottom: 1px solid #f0f0f0;

      &:last-child {
        border-bottom: none;
      }

      .icon-share-edit {
        &:hover {
          &::before {
            color: #898989;
          }
        }
        &.btn-disabled {
          &:hover {
            &::before {
              color: #d9d9d9;
            }
          }
        }
      }

      .icon-share-del {
        &::before {
          color: #afafaf;
        }

        &:hover {
          &::before {
            color: #e74362;
          }
        }
        &.btn-disabled {
          &::before {
            color: #d9d9d9;
          }

          &:hover {
            &::before {
              color: #d9d9d9;
            }
          }
        }
      }
    }
  }

  .share-img-select {
    width: 100px;
    height: 60px;
    border-radius: 4px;

    /deep/.el-image__inner {
      object-fit: cover;
    }
  }
}

/deep/.teloremail-dialog {
  .el-dialog {
    &__body {
      padding: 24px;
    }

    &__header {
      display: none;
    }

    &__footer {
      padding: 16px 24px;
      border-top: 0.5px solid #f0f0f0;
    }
  }

  .teloremail_input {
    line-height: 40px;
    height: 40px;

    .el-input__inner {
      height: 40px;
      background: #f5f5f5;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
    }
  }
}
/deep/.import-dialog {
  .el-dialog {
    &__body {
      padding: 24px 10px;
    }

    &__header {
      display: none;
    }

    &__footer {
      padding: 16px 24px;
    }
  }
  .el-upload {
    width: 100%;
  }
  .import-input {
    line-height: 36px;
    height: 36px;

    .el-input__inner {
      height: 36px;
      background: #f5f5f5;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      &::placeholder {
        font-size: 12px;
      }
    }
  }
}

/deep/.inner-course-dialog {
  .el-dialog {
    &__body {
      padding: 24px;
    }

    &__header {
      display: none;
    }

    &__footer {
      padding: 24px;
      border-top: 0.5px solid #f0f0f0;
    }
  }

  .course-search_input {
    line-height: 32px;
    height: 32px;

    .el-input__inner {
      height: 32px;
      background: #f5f5f5;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
    }
  }

  .course-box {
    max-height: 198px;
    overflow-y: scroll;
    margin-right: -6px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 30px;
    }
  }

  .course-item {
    border: 1px solid #f5f5f5;
    box-sizing: border-box;
    transition: all 0.5s ease;
    border-radius: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    position: relative;

    &_select {
      position: absolute;
      right: -0.5px;
      top: -0.5px;
      width: 36px;
      height: 36px;

      &-img {
        width: 100%;
        height: 100%;
      }

      .icon-share-select {
        position: absolute;
        right: 3px;
        top: 3px;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      border: 1px solid #d9d9d9;
      background-color: #d9d9d9;
    }

    span {
      color: #262626;
    }

    &.course-item_active {
      background: #f5f5f5;
      border: 1px solid #e74362;
    }
  }

  .course-img {
    width: 80px;
    height: 48px;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 16px;
  }
}

.margin_left100 {
  margin-left: 100px;
}

.share-mask2 {
  background-color: #000;
  opacity: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  visibility: hidden;
  transform: translateY(-30px);
  transition: all 300ms ease;

  &.show {
    opacity: 0.1;
    transform: translateY(0);
    visibility: visible;
  }
}
</style>
<style>
.picker.el-input .el-input__inner {
  padding-left: 8px;
}
</style>
